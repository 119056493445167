<template>
  <div class="appform">
    <PcForm v-if="!isMobileStatus"></PcForm>
    <MobileForm v-else></MobileForm>
  </div>
</template>

<script>
import PcForm from './pcform.vue'
import MobileForm from './mobileform.vue'
export default {
  components: {
    PcForm, MobileForm
  },
  data () {
    return {
      isMobileStatus: false
    }
  },
  mounted () {
    this.isMobileStatus = this.isMobile()
  },
  methods: {
    isMobile () {
      const userAgentInfo = navigator.userAgent
      const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPod']
      const getArr = Agents.filter(i => userAgentInfo.includes(i))
      return !!getArr.length
    }
  }
}
</script>
