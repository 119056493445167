<template>
    <div class="appform">
      <div class="content">
          <div class="title">
              <img src="../assets/newLogo.png" style="width:50px;margin-right: 12px;">Pre-registration
          </div>
          <el-form :model="form" :rules="formRules" ref="form">
              <div class="row">
                  <div class="item">
                      <el-form-item label="Project Code" prop="project_code">
                          <div class="input">
                              <el-select v-model="form.project_code" placeholder="Please choose project" style="width:100%">
                                  <el-option value="22-23PIE"></el-option>
                              </el-select>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Have you already joined a team?" prop="ready">
                          <div class="input">
                              <img src="../assets/choose.svg" v-if="form.ready==='Sure'">
                              <img src="../assets/nochoose.svg" v-else @click="form.ready='Sure'">Sure
                              <img src="../assets/choose.svg" style="margin-left:37px" v-if="form.ready==='No'">
                              <img src="../assets/nochoose.svg" v-else style="margin-left:37px" @click="form.ready='No'">No
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="The Way of Teaming up" prop="way">
                          <div class="input">
                              <el-select v-model="form.way" style="width:100%">
                                  <el-option value="Team Up with Schoolmates"></el-option>
                                  <el-option value="Team Up with Students from the Same Institution"></el-option>
                                  <el-option value="Friends (We are friends from different schools and do not belong to the same institution)"></el-option>
                              </el-select>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="School Name in Native Language" prop="school">
                          <div class="input">
                              <el-input v-model="form.school" :disabled="account_id"></el-input>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Country Where Your School is Located" prop="country">
                          <div class="input">
                              <el-input v-model="form.country" :disabled="account_id"></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Your Last Name in Native Language" prop="lastName">
                          <div class="input">
                              <el-input v-model="form.lastName"></el-input>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Your Given Name in Native Language" prop="givenName">
                          <div class="input">
                              <el-input v-model="form.givenName"></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Your Last Name in English on the Passport or the ID Card" prop="lastNameEn">
                          <div class="input">
                              <el-input v-model="form.lastNameEn"></el-input>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Your Given Name in English on the Passport or the ID Card" prop="givenNameEn">
                          <div class="input">
                              <el-input v-model="form.givenNameEn"></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Gender" prop="gender">
                          <div class="input">
                              <img src="../assets/choose.svg" v-if="form.gender==='Male'">
                              <img src="../assets/nochoose.svg" v-else @click="form.gender='Male'">Male
                              <img src="../assets/choose.svg" style="margin-left:37px" v-if="form.gender==='Female'">
                              <img src="../assets/nochoose.svg" v-else style="margin-left:37px" @click="form.gender='Female'">Female
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Grade" prop="grade">
                          <div class="input">
                              <el-select v-model="form.grade">
                                  <el-option value="9"></el-option>
                                  <el-option value="10"></el-option>
                                  <el-option value="11"></el-option>
                                  <el-option value="12"></el-option>
                              </el-select>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Contact Number (Mobile)" prop="mobile">
                          <div class="input">
                              <el-input v-model="form.mobile"></el-input>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Email Address" prop="email">
                          <div class="input">
                              <el-input v-model="form.email"></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Guardian Contact Number (Mobile)" prop="gMobile">
                          <div class="input">
                              <el-input v-model="form.gMobile"></el-input>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Guardian Email Address" prop="gEmail">
                          <div class="input">
                              <el-input v-model="form.gEmail"></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Adviser’s Name（ Advisers will receive notification emails during the process. You can write none if you do not have one yet.）">
                          <div class="input">
                              <el-input v-model="form.adiviserName"></el-input>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Adviser’s Affiliation">
                          <div class="input">
                              <el-input v-model="form.adiviserAff"></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div>
              <div class="row">
                  <div class="item">
                      <el-form-item label="Adviser’s Phone Number">
                          <div class="input">
                              <el-input v-model="form.adiviserMobile"></el-input>
                          </div>
                      </el-form-item>
                  </div>
                  <div class="item">
                      <el-form-item label="Adviser’s Email Address">
                          <div class="input">
                              <el-input v-model="form.adiviserEmail"></el-input>
                          </div>
                      </el-form-item>
                  </div>
              </div>
          </el-form>
          <div class="submit">
              <div class="submitBtn" @click="submit">
                  Submit
              </div>
          </div>

      </div>
    </div>
  </template>

<script>
import { saveAppForm, addStudentInDatabase, getAccount } from '../api/index'
export default {
  data () {
    return {
      account_id: this.$route.query.account_id,
      form: {
        ready: 'Sure',
        way: 'Team Up with Schoolmates',
        school: '',
        country: '',
        lastName: '',
        givenName: '',
        lastNameEn: '',
        givenNameEn: '',
        gender: 'Male',
        grade: '10',
        mobile: '',
        email: '',
        gMobile: '',
        gEmail: '',
        adiviserName: '',
        adiviserAff: '',
        adiviserMobile: '',
        adiviserEmail: '',
        project_code: '22-23PIE'
      },
      formRules: {
        ready: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        project_code: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        way: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        school: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        lastName: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        givenName: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        lastNameEn: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        givenNameEn: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        gender: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        grade: [
          { required: true, message: 'Please choose', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        email: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        gMobile: [
          { required: true, message: 'Please input', trigger: 'change' }
        ],
        gEmail: [
          { required: true, message: 'Please input', trigger: 'change' }
        ]
      }
    }
  },
  mounted () {
    if (this.account_id) {
      getAccount(this.account_id).then(res => {
        if (res.data.code === 0) {
          this.account = res.data.data
          this.form.school = res.data.data.account_name
          this.form.country = res.data.data.account_country
        }
      })
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate((val) => {
        if (val) {
          if (this.account_id) {
            addStudentInDatabase(
              this.account_id,
              this.form.lastName,
              this.form.givenName,
              this.form.lastNameEn,
              this.form.givenNameEn,
              this.form.gender,
              this.form.grade,
              'impact',
              this.form.mobile,
              this.form.email
            ).then((res) => {
              if (res.data.code === 0) {
                this.$confirm('You have successfully registered for the 22-23PIE Review with a new Positive Voice account automatically created under your email. You can now go to the home page and login with default password "voice". After you login, you can continue with your payment and other program tasks.', 'Tips', {
                  confirmButtonText: 'OK, Go to the login page',
                  cancelButtonText: 'Cancel',
                  type: 'success',
                  customClass: 'successConfirm'
                }).then(() => {
                  this.$router.push('/login')
                })
              } else {
                this.$message.error(res.data.msg)
              }
            })
          } else {
            saveAppForm(this.form).then(res => {
              if (res.data) {
                this.$message.success('Submit successfully')
              }
            })
          }
        }
      })
    }
  }
}
</script>

  <style scoped lang="scss">
  .appform{
      width: 100%;
      height: 100vh;
      min-height: 900px;
      background: url('../assets/applyBack.png');
      background-size: 110% 120%;
      background-position: 10% 10%;
      font-family: "DDINAlternateRegular";
      overflow-y: scroll;
       .content {
          width: 1200px;
          margin: 0 auto;
          box-sizing: border-box;
          padding: 87px 82px;
          background: #fff;
          position: relative;
          .title{
              font-family: PingFang SC-Bold, PingFang SC;
              font-weight: bold;
              color: #F65D4D;
              font-size: 22px;
              margin-bottom: 50px;
              display: flex;
              align-items: center;
          }
          .row{
              display: flex;
              align-items: center;
              justify-content: space-between;
              .item{
                  width: 480px;
                  .label{
                      font-size: 16px;
                      font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
                      font-weight: normal;
                      color: #666666;
                  }
                  .input{
                      width: 100%;
                      display: flex;
                      align-items: center;
                      font-size: 18px;

                      font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
                      font-weight: normal;
                      color: #333333;
                      img{
                          margin-right: 24px;
                          cursor: pointer;
                      }
                  }
              }
          }
      }
      .submit{
          .submitBtn{
              width: 475px;
              height: 50px;
              background: #0E4890;
              border-radius: 25px;
              text-align: center;
              line-height: 50px;
              color:#fff;
              font-size: 20px;
              margin: 0 auto;
              margin-top: 84px;
              font-weight: bold;
              cursor: pointer;
          }
          margin-bottom: 50px;
      }
  }

  </style>
  <style lang="scss">
  .appform{
      .el-input__inner {
          border-radius: 6px;
          border: 2px solid #717171;
          font-size: 18px;
      }
      .el-form-item__label{
          font-size: 16px;
          font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
          font-weight: normal;
          color: #666666;
          line-height: 30px;
          margin-bottom: 6px;
      }
  }
  .successConfirm{
      width: 470px;
      .el-message-box__status.el-icon-success{
          top: 11%!important;
      }
  }
  </style>
